<template>
  <CytomineModalCard :title="$t('select-project')" @close="$emit('close')">
    <VAsync :await="request">
      <template #default="promise">
        <div>
          <form ref="searchForm" class="mb-4" @submit.prevent="onSearch">
            <IdxInput
              v-model="search"
              name="search"
              :label="$t('search')"
              :placeholder="$t('search')"
              hide-label
              @input="onSearch"
              @keydown.enter.prevent
            />
            <button type="submit" class="visually-hidden">
              {{ $t('search') }}
            </button>
          </form>

          <BLoading v-if="promise.pending" :is-full-page="false" active />

          <IdxTable
            :await="request"
            :data="projects"
            :current-page.sync="currentPage"
            :per-page.sync="perPage"
            :total="total"
          >
            <template #default>
              <BTableColumn v-slot="props" :label="$t('name')">
                <IdxBtn plain @click="cloneToProject(images, props.row)">
                  {{ props.row.name }}
                </IdxBtn>
              </BTableColumn>

              <BTableColumn v-slot="props" width="1">
                <IdxBtn
                  small
                  color="blue"
                  @click="cloneToProject(images, props.row)"
                >
                  {{ $t('select') }}
                </IdxBtn>
              </BTableColumn>
            </template>
          </IdxTable>
        </div>
      </template>
    </VAsync>
  </CytomineModalCard>
</template>

<script>
import debounce from 'lodash/debounce.js';
import noteApi from '../../services/noteApi.js';
import CytomineModalCard from '../utils/CytomineModalCard.vue';
import IdxTable from '../utils/IdxTable.vue';

export default {
  components: {
    CytomineModalCard,
    IdxTable,
  },
  props: {
    /** @type {import('vue').PropOptions<CytoImageInstance[]>} */
    images: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    request: null,
    projects: [],
    total: 0,
    search: '',
    currentPage: 1,
    perPage: 10,
  }),
  watch: {
    currentPage: {
      handler() {
        this.fetchProjects();
      },
      immediate: true,
    },
    perPage: 'fetchProjects',
  },
  methods: {
    fetchProjects() {
      this.request = async () => {
        const { search, currentPage, perPage } = this;
        const query = {
          max: perPage,
          offset: perPage * (currentPage - 1),
          sort: 'created',
        };
        if (search.trim()) {
          query['name[ilike]'] = search.trim();
        }
        const results = await noteApi.get('/napi/project', {
          query: query,
        });
        this.projects = results.collection;
        this.total = results.size;
        return results;
      };
    },

    onSearch: debounce(function() {
      this.fetchProjects();
    }, 500),

    /**
     * @param {CytoImageInstance[]} images
     * @param {CytoProject} project
     */
    async cloneToProject(images, project) {
      try {
        this.$emit('close');

        const imageIds = images.map((image) => image.id);
        await noteApi.post(`/napi/project/${project.id}/image`, {
          json: {
            imageIds: imageIds,
          },
        });
        this.$notify({ type: 'success', text: this.$t('success') });
      } catch (error) {
        this.$notify({
          type: 'error',
          text: this.$t('unexpected-error-info-message'),
        });
      }
    },
  },
};
</script>
